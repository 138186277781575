import { ElMessage } from "element-plus";
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocation
} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import TyreResourcesView from "@/views/TyreResourcesView.vue";
import WhenToChangeTyresView from "@/views/tyre-resources/WhenToChangeTyresView.vue";
import WhatTyresAreRightView from "@/views/tyre-resources/WhatTyresAreRightView.vue";
import WhatDoesOEMeanView from "@/views/tyre-resources/WhatDoesOEMeanView.vue";
import WhatTyresFitMyLifestyleView from "@/views/tyre-resources/WhatTyresFitMyLifestyleView.vue";
import CalculatorsView from "@/views/tyre-resources/CalculatorsView.vue";
import TyreServicesView from "@/views/tyre-resources/TyreServicesView.vue";
import TestimonialsView from "@/views/tyre-resources/TestimonialsView.vue";
import WhatMakesUsDifferentView from "@/views/tyre-resources/WhatMakesUsDifferentView.vue";
import TyreMarkingsView from "@/views/tyre-resources/TyreMarkingsView.vue";
import WhereCanIGoView from "@/views/tyre-resources/WhereCanIGoView.vue";
import TyreRangeView from "@/views/tyre-range/TyreRangeView.vue";
import TyreDetailView from "@/views/tyre-range/TyreDetailView.vue";
import PromotionListView from "@/views/promotion/PromotionListView.vue";
import PromotionDetailView from "@/views/promotion/PromotionDetailView.vue";
import CampaignCaptureView from "@/views/promotion/CampaignCaptureView.vue";
import ContactView from "@/views/contact/ContactView.vue";
import RequestQuoteView from "@/views/contact/RequestQuoteView.vue";
import BecomeAFranchiseApplicationView from "@/views/contact/BecomeAFranchiseApplicationView.vue";
import TechnicalArticleListView from "@/views/tyre-resources/TechnicalArticleListView.vue";
import TechnicalArticleView from "@/views/tyre-resources/TechnicalArticleView.vue";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import CookiePolicyView from "@/views/CookiePolicyView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import TermsAndConditionsView from "@/views/TermsAndConditionsView.vue";
import GetAHiqCompanyAccountView from "@/views/tyre-resources/GetAHiqCompanyAccountView.vue";
import ValueAddedServicesListView from "@/views/tyre-resources/ValueAddedServicesListView.vue";
import ValueAddedServicesView from "@/views/tyre-resources/ValueAddedServicesView.vue";
import WheelBalancingView from "@/views/products-and-services/WheelBalancingView.vue";
import WheelAlignmentView from "@/views/products-and-services/WheelAlignmentView.vue";
import WheelRotationView from "@/views/products-and-services/WheelRotationView.vue";
import TyreRepairsView from "@/views/products-and-services/TyreRepairsView.vue";
import StoreLocatorView from "@/views/store/StoreLocatorView.vue";
import StoreDetailView from "@/views/store/StoreDetailView.vue";
import DecisionTreeView from "@/views/decision-tree/DecisionTreeView.vue";
import DecisionTreeResultsView from "@/views/decision-tree/DecisionTreeResultsView.vue";
import ProductRangeView from "@/views/product-range/ProductRangeView.vue";
import BatteriesView from "@/views/product-range/BatteriesView.vue";
import BrakesView from "@/views/product-range/BrakesView.vue";
import ExhaustsView from "@/views/product-range/ExhaustsView.vue";
import ShocksView from "@/views/product-range/ShocksView.vue";
import TowbarsView from "@/views/product-range/TowbarsView.vue";
import WheelsView from "@/views/product-range/WheelsView.vue";
import WheelsBrandView from "@/views/product-range/WheelsBrandView.vue";
import WheelsDetailView from "@/views/product-range/WheelsDetailView.vue";
import BestBuysView from "@/views/promotion/best-buys-2024/Index.vue";
import BestBuysPricingView from "@/views/promotion/best-buys-2024/BestBuysPricing.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/home",
    redirect: "/",
  },
  {
    path: "/tyre-resources",
    name: "TyreResources",
    component: TyreResourcesView,
  },
  {
    path: "/tyre-resources/when-to-change-my-tyres",
    name: "WhenToChangeTyres",
    component: WhenToChangeTyresView,
  },
  {
    path: "/tyre-resources/what-tyres-are-right-for-my-car",
    name: "WhatTyresAreRight",
    component: WhatTyresAreRightView,
  },
  {
    path: "/tyre-resources/what-does-oe-mean",
    name: "WhatDoesOEMean",
    component: WhatDoesOEMeanView,
  },
  {
    path: "/tyre-resources/what-tyres-fit-my-lifestyle",
    name: "WhatTyresFitMyLifestyle",
    component: WhatTyresFitMyLifestyleView,
  },
  {
    path: "/tyre-resources/calculators",
    name: "Calculators",
    component: CalculatorsView,
  },
  {
    path: "/tyre-services",
    name: "TyreServices",
    component: TyreServicesView,
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: TestimonialsView,
  },
  {
    path: "/tyre-resources/what-makes-us-different",
    name: "WhatMakesUsDifferent",
    component: WhatMakesUsDifferentView,
  },
  {
    path: "/tyre-resources/tyre-markings",
    name: "TyreMarkings",
    component: TyreMarkingsView,
  },
  {
    path: "/tyre-resources/where-can-i-go",
    name: "WhereCanIGo",
    component: WhereCanIGoView,
  },
  {
    path: "/tyre-resources/get-a-hiq-fleet-account",
    name: "GetAHiqCompanyAccount",
    component: GetAHiqCompanyAccountView,
  },
  {
    path: "/value-added-services",
    name: "ValueAddedServicesList",
    component: ValueAddedServicesListView,
  },
  {
    path: "/value-added-services/:slug",
    name: "ValueAddedServices",
    props: true,
    component: ValueAddedServicesView,
  },
  {
    path: "/other-products-and-services/wheel-balancing",
    name: "WheelBalancing",
    component: WheelBalancingView,
  },
  {
    path: "/other-products-and-services/wheel-alignment",
    name: "WheelAlignment",
    component: WheelAlignmentView,
  },
  {
    path: "/other-products-and-services/wheel-rotation",
    name: "WheelRotation",
    component: WheelRotationView,
  },
  {
    path: "/other-products-and-services/tyre-repairs",
    name: "TyreRepairs",
    component: TyreRepairsView,
  },
  {
    path: "/tyres/:size?",
    name: "TyreRange",
    props: true,
    component: TyreRangeView,
  },
  {
    path: "/brand/:brand?/:size?",
    name: "BrandRange",
    props: true,
    component: TyreRangeView,
  },
  {
    path: "/tyre/:brand/:slug",
    name: "TyreDetail",
    props: true,
    component: TyreDetailView,
  },
  {
    path: "/products",
    name: "ProductRange",
    component: ProductRangeView,
  },
  {
    path: "/products/batteries",
    name: "Batteries",
    component: BatteriesView,
  },
  {
    path: "/products/brakes",
    name: "Brakes",
    component: BrakesView,
  },
  {
    path: "/products/exhausts",
    name: "Exhausts",
    component: ExhaustsView,
  },
  {
    path: "/products/shock-absorbers",
    name: "Shocks",
    component: ShocksView,
  },
  {
    path: "/products/towbars",
    name: "Towbars",
    component: TowbarsView,
  },
  {
    path: "/products/wheels",
    name: "Wheels",
    component: WheelsView,
  },
  {
    path: "/products/wheels/:brand",
    name: "WheelBrand",
    component: WheelsBrandView,
  },
  {
    path: "/products/wheels/:brand/:slug",
    name: "WheelDetail",
    component: WheelsDetailView,
  },
  {
    path: "/store-locator",
    name: "StoreLocator",
    component: StoreLocatorView,
  },
  {
    path: "/store-locator/:region?",
    name: "StoreLocatorSearch",
    props: true,
    component: StoreLocatorView,
  },
  {
    path: "/store-locator/:region/:slug",
    name: "StoreView",
    props: true,
    component: StoreDetailView,
  },
  {
    path: "/promotions",
    name: "Promotions",
    component: PromotionListView,
  },
  {
    path: "/promotions/:slug",
    name: "PromotionDetailView",
    props: true,
    component: PromotionDetailView,
  },
  {
    path: "/promotions/best-buys-2024",
    name: "BestBuysView",
    component: BestBuysView,
  },
  {
    path: "/promotions/best-buys-2024/:region",
    name: "BestBuysPricingView",
    props: true,
    component: BestBuysPricingView,
  },
  {
    path: "/campaign-capture",
    name: "CampaignCapture",
    component: CampaignCaptureView,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
  {
    path: "/request-a-quote",
    name: "RequestQuote",
    props: true,
    component: RequestQuoteView,
  },
  {
    path: "/become-a-franchise",
    name: "BecomeAFranchise",
    component: BecomeAFranchiseApplicationView,
  },
  {
    path: "/decision-tree",
    name: "DecisionTree",
    component: DecisionTreeView,
  },
  {
    path: "/decision-tree/results",
    name: "DecisionTreeResults",
    props: true,
    component: DecisionTreeResultsView,
  },
  {
    path: "/technical-articles/:category?",
    name: "TechnicalArticleList",
    props: true,
    component: TechnicalArticleListView,
  },
  {
    path: "/technical-articles/:category/:slug",
    name: "TechnicalArticle",
    props: true,
    component: TechnicalArticleView,
  },
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    component: CookiePolicyView,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyView,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditionsView,
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFoundView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if ((from && Object.keys(to.query).length) || (to && Object.keys(from.query).length)) {
      // Stop scroll behaviour when replacing route wihtout changing pages
      if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
    } else if (savedPosition) {
      return { savedPosition, behavior: "smooth" };
    } else if (to.hash) {
      const navBar = document.querySelector("#navbar") as HTMLElement;
      return { el: to.hash, top: (navBar?.offsetHeight + 5) || 0, behavior: "smooth" };
    } else if (from.name != to.name && !tyreRangeTest(from, to)) {
      return { top: 0, behavior: "smooth" };
    }
  },
});

function tyreRangeTest (from: any, to: any) {
  if (from.name && to.name) {
    return from.name.toLowerCase().includes("range") && to.name.toLowerCase().includes("range")
  }
  return false;
}

export default router;
