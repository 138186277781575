interface CarPricing {
    good: number;
    better: number;
    best: number;
}

interface RegionPricing {
    [carType: string]: CarPricing;
}

interface RegionDetails {
    displayName: string;
    pricing: RegionPricing;
}

interface PricingData {
    [region: string]: RegionDetails;
}

export const BestBuys2024Pricing: PricingData = {
    "eastern-cape": {
        "displayName": "Eastern Cape",
        "pricing": {
            "Hatchback": { good: 695​, better: 750, best: 950​ },
            "Passenger": { good: 750, better: 875, best: 1250 },
            "SUV": { good: 1050, better: 1195​, best: 1295 },
            "4X4": { good: 1850​, better: 2250, best: 2650 }
        }
    },
    "free-state": {
        "displayName": "Free State",
        "pricing": {
            "Hatchback": { good: 780, better: 840, best: 980​ },
            "Passenger": { good: 860​, better: 1050, best: 1080​ },
            "SUV": { good: 1070, better: 1390, best: 1610 },
            "4X4": { good: 1290, better: 1590, best: 1930​ }
        }
    },
    "gauteng": {
        "displayName": "Gauteng",
        "pricing": {
            "Hatchback": { good: 778, better: 800​, best: 830 },
            "Passenger": { good: 748​, better: 775, best: 1190 },
            "SUV": { good: 1220, better: 1360, best: 1560 },
            "4X4": { good: 1425​, better: 1525​, best: 1600 }
        }
    },
    "kwazulu-natal": {
        "displayName": "KwaZulu-Natal",
        "pricing": {
            "Hatchback": { good: 600​, better: 750​, best: 960​ },
            "Passenger": { good: 670, better: 800, best: 960 },
            "SUV": { good: 1045, better: 1290, best: 1400 },
            "4X4": { good: 1130, better: 1250​, best: 1800 }
        }
    },
    "limpopo": {
        "displayName": "Limpopo",
        "pricing": {
            "Hatchback": { good: 710, better: 970, best: 1100 },
            "Passenger": { good: 870, better: 1250, best: 1660 },
            "SUV": { good: 1399, better: 1799, best: 2180 },
            "4X4": { good: 1950, better: 2350, best: 2750 }
        }
    },
    "mpumalanga": {
        "displayName": "Mpumalanga",
        "pricing": {
            "Hatchback": { good: 720, better: 960, best: 1250​ },
            "Passenger": { good: 935, better: 1020, best: 1280 },
            "SUV": { good: 1080, better: 1400, best: 1540 },
            "4X4": { good: 1390, better: 1470, best: 2020 }
        }
    },
    "northern-cape": {
        "displayName": "Northern Cape",
        "pricing": {
            "Hatchback": { good: 780, better: 840​, best: 980​ },
            "Passenger": { good: 860​, better: 1050, best: 1080 },
            "SUV": { good: 1070, better: 1390, best: 1610 },
            "4X4": { good: 1290, better: 1590, best: 1930​ }
        }
    },
    "north-west": {
        "displayName": "North West",
        "pricing": {
            "Hatchback": { good: 780​, better: 840​, best: 980​ },
            "Passenger": { good: 860, better: 1050​, best: 1080​ },
            "SUV": { good: 1070, better: 1390, best: 1610 },
            "4X4": { good: 1290​, better: 1590, best: 1930 }
        }
    },
    "western-cape": {
        "displayName": "Western Cape",
        "pricing": {
            "Hatchback": { good: 710, better: 859​, best: 1230 },
            "Passenger": { good: 729​, better: 850, best: 1395 },
            "SUV": { good: 999, better: 1250, best: 1550 },
            "4X4": { good: 1350​, better: 1580, best: 1780 }
        }
    }
}